// 获取生活通子分类
const getLifeNewsTypeUrl = `/gateway/hc-neighbour/topic/getLifeNewsType`;
// 获取搜索历史
const getSearchHistoryUrl = `/gateway/hc-neighbour/topic/getSearchHistory`;
// 删除搜索历史
const deleteSearchHistoryUrl = `/gateway/hc-neighbour/topic/deleteSearchHistory`;
// 搜索
const getTopicListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
// 获取热搜
const getHotSearchUrl = `/gateway/hc-neighbour/topic/getHotSearch`;
// 简单搜索
const getLifeTopicListUrl = `/gateway/hc-neighbour/topic/getLifeTopicList`;
export {
  getLifeNewsTypeUrl,
  getSearchHistoryUrl,
  deleteSearchHistoryUrl,
  getTopicListUrl,
  getHotSearchUrl,
  getLifeTopicListUrl,
};
